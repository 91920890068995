import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core"
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown, faPlus, faMinus } from "@fortawesome/free-solid-svg-icons"
import "./style.scss"

const CustomAccordion = ({data}) => {
  return (
    <>
        {data &&
        data.map((item, i) => (
          <Accordion className="accordion-wrapper">
              <AccordionSummary
                expandIcon={
                  <FontAwesomeIcon size="xs" icon={faAngleDown} color="black" />
                }
                aria-controls={`panel1a-content-${i}`}
                id={`panel-header-${i}`}
              >
                <Typography className="title">{item.title}</Typography>
              </AccordionSummary>

            <AccordionDetails>
              <Typography className="align-start">{item.description}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
    </>
  )
}

export default CustomAccordion
